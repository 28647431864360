import React, { useEffect, useState, useContext } from "react"
import { Flex, Box, Text, Image } from "rebass"

import { AccountContext } from "../../context/account"
import { useAuth } from "../../context/auth"
import LoginLayout from "../../components/login-layout"
import SEO from "../../components/seo"
import Spinner from "../../components/spinner"
import Button from "../../components/button"
import CodeSnippet from "../../components/code-snippet"
import Graphic from "../../assets/login-graphic.png"

const IndexPage = () => {
  const [status, setStatus] = useState("loading")
  const auth = useAuth()

  useEffect(() => {
    auth.session().then(() => {
      setStatus("confirmed")
    }).catch(() => {
      setStatus("error")
    })
  }, [])

  return (
    <LoginLayout pt={3}>
      <SEO title="CLI Authentication Success" />
      <Text mb={4} fontWeight="bold" fontSize={4}>
        CLI Authentication Completed
      </Text>
      {status === "loading" && (
        <Flex justifyContent="center">
          <Spinner dark width="20px" height="20px" />
        </Flex>
      )}
      {status === "confirmed" && (
        <Flex sx={{ flexDirection: "column", width: "100%" }}>
          <Text mb={2} fontSize="1">
            You can now link your local project with
          </Text>
          <CodeSnippet type="cli">medusa link</CodeSnippet>
          <Button mt={3} variant="cta" to="/a/orders">
            Go to orders
          </Button>
        </Flex>
      )}
      {status === "error" && (
        <Flex sx={{ flexDirection: "column", width: "100%" }}>
          <Flex mb={4}>
            <Text fontSize={1}>Something went wrong</Text>
          </Flex>
          <Flex>
            <Text fontSize="1">Please try authenticating again</Text>
          </Flex>
        </Flex>
      )}
    </LoginLayout>
  )
}

export default IndexPage
